import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import AppContainer from "./components/AppContainer";
import Home from "./pages/Home";
import CoverLetter from "./pages/CoverLetter";

const App = () => {
  return (
    <AppContainer>
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<Home/>}/>
          <Route exact path='/letter' element={<CoverLetter/>}/>
        </Routes>
      </BrowserRouter>
    </AppContainer>
  );
};

export default App;
