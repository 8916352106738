import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
const Home = () => {

    const navigate = useNavigate()

    const [fullName, setFullName] = useState('Vitalii Sili')
    const [phone, setPhone] = useState('+4917676272238')
    const [hiringManager, setHiringManager] = useState('Hiring Manager')
    const [companyToApply, setCompanyToApply] = useState('')
    const [companyAddress, setCompanyAddress] = useState('')
    const [companyZipCode, setCompanyZipCode] = useState('')
    const [companyCity, setCompanyCity] = useState('')
    const [personalAddress, setPersonalAddress] = useState('Allgäuer Str. 92, 81475 München')
    const [personalPosition, setPersonalPosition] = useState('Full Stack Developer')
    const [personalEmail, setPersonalEmail] = useState('vitaliisili@yahoo.com')
    const [positionToApply, setPositionToApply] = useState('')
    const [locale, setLocale] = useState('en-EN')
    // const [source, setSource] = useState('')

    const generateHandler = () => {

        if (!positionToApply && !companyToApply) {
            toast.error('Position and Company are required')
            return
        }

        const data = {
            fullName,
            phone,
            hiringManager,
            companyToApply,
            companyAddress,
            companyZipCode,
            companyCity,
            personalAddress,
            personalPosition,
            personalEmail,
            positionToApply,
            locale,
            // source
        }

        navigate('/letter', {state: data})
    }

    return (
        <div className='flex h-screen justify-center items-center bg-gradient-to-t from-indigo-950 to-sky-950 text-amber-50'>
            <ToastContainer/>
            <div className='flex flex-col w-[600px]'>
                <div className='text-2xl font-bold'>Personal Info</div>
                <div className='flex flex-col text-black space-y-3 mt-4'>
                    <input className='backdrop-blur-sm bg-white/70 p-3 rounded-sm' type="text" placeholder='Full Name' onChange={(e) => setFullName(e.target.value)} value={fullName}/>
                    <div className='flex'>
                        <input className='backdrop-blur-sm bg-white/70 w-full p-3 rounded-sm' type="text" placeholder='Phone Number' onChange={(e) => setPhone(e.target.value)} value={phone}/>
                        <input className='backdrop-blur-sm bg-white/70 w-full p-3 ml-3 rounded-sm' type="text" placeholder='Personal Email' onChange={(e) => setPersonalEmail(e.target.value)} value={personalEmail}/>
                    </div>
                    <input className='backdrop-blur-sm bg-white/70 p-3 rounded-sm' type="text" placeholder='Current Position' onChange={(e) => setPersonalPosition(e.target.value)} value={personalPosition}/>
                    <input className='backdrop-blur-sm bg-white/70 p-3 rounded-sm' type="text" placeholder='Personal Address' onChange={(e) => setPersonalAddress(e.target.value)} value={personalAddress}/>
                </div>

                <div className='text-2xl font-bold mt-6'>Company Info</div>
                    <div className='flex flex-col text-black space-y-3 mt-4'>
                        <input className='backdrop-blur-sm bg-white/70 p-3 rounded-sm' type="text" placeholder='Position to apply' onChange={(e) => setPositionToApply(e.target.value)} value={positionToApply}/>
                        <div className='flex'>
                            <input className='backdrop-blur-sm bg-white/70 w-full p-3 rounded-sm' type="text" placeholder='Hiring manager name' onChange={(e) => setHiringManager(e.target.value)} value={hiringManager}/>
                            <input className='backdrop-blur-sm bg-white/70 w-full p-3 ml-3 rounded-sm' type="text" placeholder='Company name' onChange={(e) => setCompanyToApply(e.target.value)} value={companyToApply}/>
                        </div>
                        <input className='backdrop-blur-sm bg-white/70 p-3 rounded-sm' type="text" placeholder='Company adress' onChange={(e) => setCompanyAddress(e.target.value)} value={companyAddress}/>
                        <div className='flex'>
                            <input className='backdrop-blur-sm bg-white/70 w-full p-3 rounded-sm' type="text" placeholder='Zip code' onChange={(e) => setCompanyZipCode(e.target.value)} value={companyZipCode}/>
                            <input className='backdrop-blur-sm bg-white/70 w-full p-3 ml-3 rounded-sm' type="text" placeholder='City' onChange={(e) => setCompanyCity(e.target.value)} value={companyCity}/>
                        </div>
                        {/*<input onChange={(e) => setSource(e.target.value)} value={source} className='backdrop-blur-sm bg-white/70 p-3 rounded-sm' type="text" placeholder='Where you found position'/>*/}
                        <div className='flex justify-around text-amber-50 pt-2 pb-2'>
                            <div className="flex items-center">
                                <input onChange={(e) => setLocale(e.target.value)} checked id="default-radio-1" type="radio" value="en-EN" name="default-radio"
                                       className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    <label htmlFor="default-radio-1"
                                           className="ml-2 -mt-1">English</label>
                            </div>
                            <div className="flex items-center">
                                <input onChange={(e) => setLocale(e.target.value)} id="default-radio-2" type="radio" value="de-DE" name="default-radio"
                                       className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    <label htmlFor="default-radio-2"
                                           className="ml-2 -mt-1">German</label>
                            </div>
                        </div>

                    </div>

                <button onClick={generateHandler} className='mt-4 uppercase font-bold w-full bg-sky-950 p-4 rounded-sm'>Generate Cover Letter</button>
            </div>
        </div>
    );
};

export default Home;