import React, {useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import {FaHome, FaLinkedin} from "react-icons/fa";
import {FaPhone} from "react-icons/fa6";
import {MdEmail} from "react-icons/md";
import {FaGithub} from "react-icons/fa";

const CoverLetter = () => {

    const {state} = useLocation()

    const currentDate = new Date();
    const month = currentDate.toLocaleString(state.locale, {
        month: "long",
    });

    const formattedDate = `${currentDate.getDate()} ${month} ${currentDate.getFullYear()}`

    const [intro, setIntro] = useState(`
    I am excited to express my interest in the ${state.positionToApply} position at ${state.companyToApply}. 
    My passion for software development aligns perfectly with your company's commitment to technological innovation. 
    My dedication to learning and my hands-on experience in personal projects make me confident in my ability to contribute effectively to your team.`)

    const [body, setBody] = useState(`
    My educational background includes a comprehensive Python course at DCI Digital Career Institute, 
    where I gained a solid foundation in programming principles, data structures, algorithms, and object-oriented programming. 
    I have a deep understanding of Python's capabilities and am confident in my ability to use it effectively to address complex software development challenges.
    In addition to my formal education, I have been actively engaged in personal projects to further enhance my skills 
    and explore my creativity in software development. These projects have allowed me to delve into various aspects of full-stack development, 
    including frontend and backend programming, database management, and user interface design.`)

    const [skill, setSkill] = useState(`
    I am known for my analytical thinking, problem-solving abilities, and strong attention to detail, which I believe 
    are essential qualities for a successful Full-Stack Developer. Furthermore, I am a highly motivated individual who 
    is eager to learn and grow professionally. I am confident that I can quickly adapt to your company's development 
    environment and make significant contributions to your ongoing projects.
    `)

    const [footer, setFooter] = useState(`
    Thank you for considering my application. I have attached my resume for your review, which provides further details 
    on my education, skills, and personal projects. I would welcome the opportunity to discuss how my qualifications 
    align with your company's requirements in more detail.`)

    const [introActive, setIntroActive] = useState(false)
    const [bodyActive, setBodyActive] = useState(false)
    const [skillActive, setSkillActive] = useState(false)
    const [footerActive, setFooterActive] = useState(false)

    return (
        <div className='flex justify-center' id='cover-letter'>
            <div className='w-[1200px] h-screen flex flex-col justify-between'>
                <div className='flex flex-col'>

                    <section className='bg-[#6A6A6A] w-full p-6 flex justify-between'>
                        <div className=''>
                            <div className='text-[#58B8F2] text-6xl font-bold font-poppins'>{state.fullName}</div>
                            <div
                                className='text-amber-50 uppercase font-poppins mt-2 ml-2'>{state.personalPosition}</div>
                        </div>
                        <div className='space-y-2'>
                            <div className='flex items-center space-x-2 text-amber-50'><FaHome className='text-xl'/>
                                <div>{state.personalAddress}</div>
                            </div>
                            <div className='flex items-center space-x-2 text-amber-50'><FaPhone className='text-xl'/>
                                <div>{state.phone}</div>
                            </div>
                            <div className='flex items-center space-x-2 text-amber-50'><MdEmail className='text-xl'/>
                                <div>{state.personalEmail}</div>
                            </div>
                        </div>
                    </section>

                    <section className='flex p-6 justify-between'>
                        <div className='flex flex-col'>
                            <div>Dear {state.hiringManager}</div>
                            <div>{state.companyToApply}</div>
                            <div>{state.companyAddress}</div>
                            <div>{state.companyZipCode}, {state.companyCity}</div>
                        </div>
                        <div className='flex'>
                            <div className='self-end'>{formattedDate}</div>
                        </div>
                    </section>

                    <section>
                        <div
                            className='text-xl text-[#58B8F2] px-6 font-bold'>Subject: {state.positionToApply} at {state.companyToApply}</div>
                    </section>

                    <section className='px-6 mt-6'>
                        <div>Dear {state.hiringManager},</div>
                    </section>

                    <section className='p-6 relative'>
                        <div onDoubleClick={() => setIntroActive(true)}>
                            {!introActive ?
                                <div className='has-tooltip'>
                                    <span className='tooltip -mt-8 text-[#58B8F2] right-10'>Double Click to Edit</span>
                                    {intro}
                                </div> :
                                <textarea className='w-full' name="intro" id="intro" rows="6" value={intro}
                                          onChange={(e) => {
                                              setIntro(e.target.value)
                                          }}></textarea>
                            }
                            <div className='flex w-full justify-end'>
                                {introActive &&
                                    <button className='text-[#58B8F2]'
                                            onClick={() => setIntroActive(false)}>Save</button>
                                }
                            </div>
                        </div>
                    </section>

                    <section className='px-6 relative'>
                        <div onDoubleClick={() => setBodyActive(true)}>
                            {!bodyActive ?
                                <div className='has-tooltip'>
                                    <span className='tooltip -mt-8 text-[#58B8F2] right-10'>Double Click to Edit</span>
                                    {body}
                                </div> :
                                <textarea className='w-full' name="body" id="body" rows="12" value={body}
                                          onChange={(e) => {
                                              setBody(e.target.value)
                                          }}></textarea>
                            }
                            <div className='flex w-full justify-end'>
                                {bodyActive &&
                                    <button className='text-[#58B8F2]'
                                            onClick={() => setBodyActive(false)}>Save</button>
                                }
                            </div>
                        </div>
                    </section>

                    <section className='px-6 mt-6 relative'>
                        <div onDoubleClick={() => setSkillActive(true)}>
                            {!skillActive ?
                                <div className='has-tooltip'>
                                    <span className='tooltip -mt-8 text-[#58B8F2] right-10'>Double Click to Edit</span>
                                    {skill}
                                </div> :
                                <textarea className='w-full' name="body" id="body" rows="12" value={skill}
                                          onChange={(e) => {
                                              setSkill(e.target.value)
                                          }}></textarea>
                            }
                            <div className='flex w-full justify-end'>
                                {skillActive &&
                                    <button className='text-[#58B8F2]'
                                            onClick={() => setSkillActive(false)}>Save</button>
                                }
                            </div>
                        </div>
                    </section>

                    <section className='p-6 relative'>
                        <div onDoubleClick={() => setFooterActive(true)}>
                            {!footerActive ?
                                <div className='has-tooltip'>
                                    <span className='tooltip -mt-8 text-[#58B8F2] right-10'>Double Click to Edit</span>
                                    {footer}
                                </div> :
                                <textarea className='w-full' name="intro" id="intro" rows="6" value={footer}
                                          onChange={(e) => {
                                              setFooter(e.target.value)
                                          }}></textarea>
                            }
                            <div className='flex w-full justify-end'>
                                {footerActive &&
                                    <button className='text-[#58B8F2]'
                                            onClick={() => setFooterActive(false)}>Save</button>
                                }
                            </div>
                        </div>
                    </section>

                    <section className='px-6 '>
                        <div>Thank you for your time and consideration.</div>
                    </section>

                    <section className='px-6 mt-6'>
                        <div>Kind regards,</div>
                        <div>{state.fullName}</div>
                    </section>
                </div>
                <section className='justify-center items-center p-3 mt-6 text-amber-50 flex space-x-6 bg-[#6A6A6A]'>
                    <Link to='https://github.com/vitaliisili'><FaGithub className='text-xl'/></Link>
                    <Link to='https://www.linkedin.com/in/vitaliisili/'><FaLinkedin className='text-xl'/></Link>
                </section>
            </div>
        </div>
    );
};

export default CoverLetter;